import React from 'react';
import styled from 'styled-components/macro';

const Content = styled.div`
    width: 80%;
    margin: 10rem auto;
    max-width: 40em;
    padding: 2em;
    border-radius: 1em;
    box-shadow: 
        0 0 0 10px #ee6352,
        0 0 0 20px #d45379,
        0 0 0 30px #a4558f,
        0 0 0 40px #6d588e,
        0 0 0 50px #405378;

    @media screen and (max-width: 480px) {
        width: 70%;
        margin: 6rem auto;
    }
    
    p {
        text-align: center;
        font-size: 1.5rem;

        @media screen and (max-width: 480px) {
            font-size: 1rem;
        }
    }
`;

const FancyCake = () => {
    return (
        <>
            <Content>
                <p>TORT INCLUS LA PESTE 130 DE PERSOANE!!!</p>
            </Content>
        </>
    )
}

export default FancyCake;
