import React, { useState } from 'react';
import Dropdown from './components/Dropdown';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import GlobalStyle from './globalStyles';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import DespreNoi from './pages/DespreNoi';
import Meniuri from './pages/Meniuri';
import Galerie from './pages/Galerie';
import ContactUs from './pages/ContactUs';

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  return (
    <Router>
      <GlobalStyle />
      <Navbar toggle={toggle} />
      <Dropdown isOpen={isOpen} toggle={toggle} />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/desprenoi' exact component={DespreNoi} />
        <Route path='/meniuri' exact component={Meniuri} />
        <Route path='/galerie' exact component={Galerie} />
        <Route path='/contactus' exact component={ContactUs} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
