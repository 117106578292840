import React from 'react';
import TitluGalerie from '../components/TitluGalerie';
import { AboutTitleData, EventsTitleData } from '../data/TitleData';
import styled from 'styled-components/macro';
import ImageOne from '../images/main_bkg.jpg';
import ImageTwo from '../images/castanilor3.jpg';
import ImageThree from '../images/galerie_7.jpg';

const About = styled.div`
    background-image: url(${ImageOne});
    max-height: 1100px;
    position: relative;
    overflow: hidden;

    @media screen and (max-width: 480px) {
        max-height: unset;
    }
`;

const AboutContainer = styled.div`
    position: relative;
    overflow: hidden;
    padding: 0;
    max-width: 1170px;
    height: 600px;
    margin: 40px auto 120px; 
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 480px) {
        display: flex;
        flex-direction: column !important;
        height: auto;
        margin-top: 0px !important;
    }
`;

const SectionImage = styled.div`
    background-image: url(${ImageTwo});
    width: 50%;
    position: relative;
    background-position: center left;
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (max-width: 480px) {
        width: 100%;
        height: 500px;
    }
`;

const SectionStory = styled.div`
    width: 50%;
    position: relative;
    background-color: #ffffff;
    padding: 4.2em;

    @media screen and (max-width: 480px) {
        width: 100%;
        padding: 1.2em;
    }
`;

const SectionTitle = styled.h4`
    color: #808369;
    font-size: 1.571rem;
    margin-bottom: 2rem;
    text-align: left;
    margin-left: 0;
    font-weight: 600;

    @media screen and (max-width: 480px) {
        text-align: center;
    }
`;

const Story = styled.p`
    text-align: left;
    margin-left: 0;
    color: #929292;
    font-size: 0.95rem;
    margin-bottom: 1rem;
`;

const Events = styled.div`
    position: relative;
    overflow: hidden;
`;

const EventsContainer = styled.div`
    position: relative;
    overflow: hidden;
    padding: 0;
    max-width: 1170px;
    height: 600px;
    margin: 40px auto 120px; 
    display: flex;

    @media screen and (max-width: 480px) {
        margin-bottom: 0px !important;
    }
`;

const EventsImageContainer = styled.div`
    position: absolute;
    width: 37%;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    text-align: center;

    @media screen and (max-width: 480px) {
        display: none;
    }
`;

const EventsImage = styled.img`
    max-width: 100%;
    height: auto;
    vertical-align: top;
    border-radius: 50%;
`;

const EventsNest = styled.div`
    margin-left: 0px;
    margin-right: -30px;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const EventRow = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    margin-bottom: 4rem;

    @media screen and (max-width: 480px) {
        margin-bottom: 2rem;
        padding: 0 1%;
    }
`;
const EventCol = styled.div`
    width: 50%;
`;

const EventOdd = styled.div`
    margin-right: 41%;

    @media screen and (max-width: 480px) {
        margin-right: 2%;
    }
`;
const EventEven = styled.div`
    margin-left: 41%;

    @media screen and (max-width: 480px) {
        margin-left: 2%;
    }
`;

const EventItemContent = styled.div`
    text-align: right;
    padding-right: 19%;
    padding-left: 0;
    position: relative;

    @media screen and (max-width: 480px) {
        padding-right: 1%;
        text-align: center;
    }
`;

const EventItemContentEven = styled.div`
    text-align: left;
    padding-left: 19%;
    padding-right: 0;
    position: relative;

    @media screen and (max-width: 480px) {
        padding-left: 1%;
        text-align: center;
    }
`;

const EventNumber = styled.div`
    color: #eae7e0;
    right: 0;
    font-size: 2.857rem;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    line-height: 0.9;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;

    &:hover {
        color: #ffb18b;
    }

    @media screen and (max-width: 480px) {
        top: -5px;
        right: unset;
        left: 0;
    }
`;

const EventNumberEven = styled.div`
    color: #eae7e0;
    left: 0;
    font-size: 2.857rem;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    line-height: 0.9;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;

    &:hover {
        color: #ffb18b;
    }

    @media screen and (max-width: 480px) {
        top: -5px;
        right: 0;
        left: unset;
    }
`;

const EventTitle = styled.h4`
    color: #808369;
    font-size: 1.286rem;
    font-weight: 700;
    position: relative;
    padding-bottom: 0.42em;
    margin-top: 0.7em;
    margin: 1em 0 0;
    text-transform: uppercase;
    line-height: 1.5em;
    text-align: right;

    &::before {
        right: 0;
        background-color: #808369;
        content: '';
        height: 2px;
        width: 7.929rem;
        position: absolute;
        bottom: 0;
    }
`;

const EventTitleEven = styled.h4`
    color: #808369;
    font-size: 1.286rem;
    font-weight: 700;
    position: relative;
    padding-bottom: 0.42em;
    margin-top: 0.7em;
    margin: 1em 0 0;
    text-transform: uppercase;
    line-height: 1.5em;
    text-align: left;

    &::before {
        left: 0;
        background-color: #808369;
        content: '';
        height: 2px;
        width: 7.929rem;
        position: absolute;
        bottom: 0;
    }
`;

const EventDescription = styled.p`
    margin-top: 1.8857em;
    line-height: 1.45em;
    overflow: hidden;
    margin-bottom: 0;
    color: #929292;
    font-size: 0.95rem;

    @media screen and (max-width: 480px) {
        font-size: 0.75rem !important;
    }
`;

const EventDescriptionEven = styled.p`
    margin-top: 1.8857em;
    line-height: 1.45em;
    overflow: hidden;
    margin-bottom: 0;
    color: #929292;
    font-size: 0.95rem;
    text-align: left;

    @media screen and (max-width: 480px) {
        font-size: 0.75rem !important;
    }
`;

const DespreNoi = () => {
    return (
        <>            
            <About>
                <TitluGalerie {...AboutTitleData} />
                <AboutContainer>
                    <SectionImage></SectionImage>
                    <SectionStory>
                        <SectionTitle>Descopera Povestea Noastra</SectionTitle>
                        <Story>Restaurantul Alegria vă oferă posibilitatea servirii mesei într-un decor modern şi plăcut. Meniul este variat, cuprinzând atât rețete tradiționale Româneşti, cât şi rețete internaționale, toate pregătite cu profesionalism şi pasiune.</Story>
                        <Story>Mobilierul poate fi aşezat în sistem modular sau extins, oferind posibilitatea organizării de banchete, petreceri, cocktail-uri, nunți sau alte mese festive, având o capacitate de 160 de locuri. Restaurantul este dotat cu aer condiționat şi sistem audio.</Story>
                        <Story>Alegria Event oferă perechilor care vor să îşi unească viețile întreaga gamă de servicii necesare pentru o ceremonie perfectă: rezervarea locației potrivite pentru recepție, alcătuirea meniului, crearea ambientului festiv (decorațiuni, aranjamente florale), şi multe altele. Apelând la Alegria Event, evitați stresul şi alergătura inerente, calitatea serviciilor este excepțională, şi cheltuiți mai puțin, datorită reducerilor negociate cu furnizorii. Veți putea profita din plin de timpul dvs., Alegria Event ocupându-se atât de conceptul, cât şi de planificarea şi managementul ceremoniei.</Story>
                    </SectionStory>
                </AboutContainer>                
            </About>
            <TitluGalerie {...EventsTitleData} />
            <Events>
                <EventsContainer>
                    <EventsImageContainer>
                        <EventsImage src={ImageThree} alt="alegria" />
                    </EventsImageContainer>
                    <EventsNest>
                        <EventRow>
                            <EventCol>
                                <EventOdd>
                                    <EventItemContent>
                                        <EventNumber>01</EventNumber>
                                        <EventTitle>Nunti şi Cununii</EventTitle>
                                        <EventDescription>Prin organizarea nunții dumneavoastră la restaurantul nostru, noi vă vom satisface toate exigențele legate de locația nunții dumneavoastră. Experiența pe care o avem ca restaurant de nunți ne recomandă pentru orice proiect pe care a l-ați avea, indiferent de amploarea acestuia.</EventDescription>
                                    </EventItemContent>
                                </EventOdd>                                
                            </EventCol>
                            <EventCol>
                                <EventEven>
                                    <EventItemContentEven>
                                        <EventNumberEven>02</EventNumberEven>
                                        <EventTitleEven>Botezuri</EventTitleEven>
                                        <EventDescriptionEven>Deşi botezul nu are anvergura unei nunți, organizarea la domiciliu este în multe cazuri aproape imposibilă, datorită efortului necesar pentru finalizarea tuturor detaliilor cât şi pentru spațiul, care de cele mai multe ori, este insuficient.</EventDescriptionEven>
                                    </EventItemContentEven>
                                </EventEven>                                
                            </EventCol>
                        </EventRow>
                        <EventRow>
                            <EventCol>
                                <EventOdd>
                                    <EventItemContent>
                                        <EventNumber>03</EventNumber>
                                        <EventTitle>Petreceri</EventTitle>
                                        <EventDescription>Pe lângă organizarea nunților şi a botezurilor Restaurantul Alegria este o gazdă primitoare pentru orice fel de petreceri (aniversări, mese festive, promovări etc). Contactați-ne pentru mai multe detalii.</EventDescription>
                                    </EventItemContent>
                                </EventOdd>                                
                            </EventCol>
                            <EventCol>
                                <EventEven>
                                    <EventItemContentEven>
                                        <EventNumberEven>04</EventNumberEven>
                                        <EventTitleEven>Pomeni</EventTitleEven>
                                        <EventDescriptionEven>Suntem alaturi de cei care si-au pierdut pe cineva drag si organizam pomeni/parastase.</EventDescriptionEven>
                                    </EventItemContentEven>
                                </EventEven>                                
                            </EventCol>
                        </EventRow>
                    </EventsNest>
                </EventsContainer>
            </Events>
        </>
    )
}

export default DespreNoi;
