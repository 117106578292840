import React from 'react';
import styled from 'styled-components';
import { FaFacebook, FaInstagram } from 'react-icons/fa';

const FooterContainer = styled.footer`
    background-color: #101522;
`;

const FooterWrap = styled.div`
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
`;

const FooterInfoContainer = styled.div`
    display: flex;
    justify-content: center;

    @media screen and (max-width: 768px) {
        padding-top: 32px;
    }
`;

const FooterInfoWrapper = styled.div`
    display: flex;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

const FooterInfoItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    box-sizing: border-box;
    color: #fff;

    @media screen and (min-width: 768px) {
        padding: 0px 100px;
    }

    @media screen and (max-width: 420px) {
        margin: 0;
        padding: 10px;
        width: 100%;
    }
`;

const FooterInfoTitle = styled.h1`
    font-size: 14px;
    margin-bottom: 16px;
`;

const FooterInfo = styled.p`
    color: #fff;
    margin-bottom: 0.5rem;
    font-size: 14px;
`;

const SocialMedia = styled.section`
    max-width: 1000px;
    width: 100%;
`;

const SocialMediaWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 40px auto 0 auto;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

const WebsiteRights = styled.div`
    color: #fff;    
    font-size: 14px;
`;

const SocialIcons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    margin-bottom: 16px;
`;

const SocialIconLink = styled.a`
    color: #fff;
    font-size: 32px;
    padding: 0 10px;
`;

const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrap>
                <FooterInfoContainer>
                    <FooterInfoWrapper>
                        <FooterInfoItems>
                            <FooterInfoTitle>ADRESĂ</FooterInfoTitle>
                            <FooterInfo>Castanilor 4A, Chitila, Ilfov</FooterInfo>
                            <a className="footerLink" href="https://www.alegriaevent.ro" rel="noreferrer" target="_blank">www.alegriaevent.ro</a>
                            <FooterInfo>Deschis în weekend doar pe bază de programări.</FooterInfo>                       
                        </FooterInfoItems>
                        <FooterInfoItems>
                            <FooterInfoTitle>CONTACT</FooterInfoTitle>
                            <a className="footerLink" href="mailto:alegria_nunti@yahoo.com">alegria_nunti@yahoo.com</a>
                            <FooterInfo>0763691420</FooterInfo>
                            <FooterInfo>0763691421</FooterInfo>     
                        </FooterInfoItems>
                    </FooterInfoWrapper>
                </FooterInfoContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialIcons>
                            <SocialIconLink href="https://www.facebook.com/Restaurant-Alegria-101711672279896" target="_blank" aria-label="Facebook"><FaFacebook /></SocialIconLink>
                            <SocialIconLink href="https://www.instagram.com/alegriaevent.ro/" target="_blank" aria-label="Instagram"><FaInstagram /></SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
                <WebsiteRights>
                    Restaurant Alegria © {new Date().getFullYear()} Toate drepturile rezervate.
                </WebsiteRights>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer;
