import React from 'react';
import styled from 'styled-components/macro';
import ImageOne from '../images/main_bkg.jpg';

const ContactContainer = styled.div`
    position: relative;
    overflow: hidden;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33.3%;
    -moz-column-width: 33.3%;
    column-width: 33.3%;
    padding: 0 12px;
    max-width: 1170px;
    margin: 0px auto 80px;   

    @media screen and (max-width: 480px) {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
    }
`;
const Contacte = styled.div`
    background-image: url(${ImageOne});  
    width: 100%;
    height: 280px;   
    padding: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 4px;

    @media screen and (max-width: 480px) {
        margin-bottom: 20px;
    }

    h3 {
        text-align: center;
        font-family: 'Yellowtail';
        font-size: 2.857rem;
        font-weight: 400;
        margin-bottom: 1.3rem;
    }

    p, a {
        text-align: center;
        font-size: 0.9rem;
        color: #929292;
    }
`;

const Contact = () => {
    return (
        <>
            <ContactContainer>                    
                <Contacte>
                    <h3>Adresa</h3>
                    <p>Castanilor 4A, Chitila, Ilfov</p>
                    <a className="footerLink" href="https://www.alegriaevent.ro" rel="noreferrer" target="_blank">www.alegriaevent.ro</a>
                </Contacte>
                <Contacte>
                    <h3>Telefon</h3>
                    <a className="footerLink" href="tel:0763691421">0763691420</a>
                    <a className="footerLink" href="tel:0763691421">0763691421</a>
                </Contacte>
                <Contacte>
                    <h3>Email</h3>
                    <a className="footerLink" href="mailto:alegria_nunti@yahoo.com">alegria_nunti@yahoo.com</a>
                    <a className="footerLink" href="mailto:adrianzlatalegria@gmail.com">adrianzlatalegria@gmail.com</a>
                </Contacte>
            </ContactContainer>
        </>
    )
}

export default Contact;
