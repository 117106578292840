import ImageOne from '../images/castanilor3.jpg';
import ImageTwo from '../images/hero-img.jpeg';
import ImageThree from '../images/alegria_3.jpg';
import ImageFour from '../images/castanilor9.jpg';
import BackgroundImage from '../images/main_bkg.jpg';

export const SliderData = [
    {
        title: 'Restaurant Alegria - Castanilor',
        path: '/desprenoi',
        label: 'Exploreaza',
        image: ImageOne,
        alt: 'alegria'
    },
    {
        title: 'Restaurant Alegria - Castanilor',
        path: '/desprenoi',
        label: 'Exploreaza',
        image: ImageTwo,
        alt: 'alegria'
    },
    {
        title: 'Restaurant Alegria - Castanilor',
        path: '/desprenoi',
        label: 'Exploreaza',
        image: ImageThree,
        alt: 'alegria'
    },
    {
        title: 'Restaurant Alegria - Castanilor',
        path: '/desprenoi',
        label: 'Exploreaza',
        image: ImageFour,
        alt: 'alegria'
    }
];

export const TestimonialsData = [
    {
        name: 'Sorin O.',
        testimonial: 'Super locație, Super servire. Totul la superlativ.',
        image: BackgroundImage,
        alt: 'alegria'
    },
    {
        name: 'Andreea si Florian',
        testimonial: 'O locație perfecta pentru petreceri unice',
        image: BackgroundImage,
        alt: 'alegria'
    },
    {
        name: 'Gabriela C.',
        testimonial: 'Este un restaurant drăguț, nu foarte mare, dar mancarea este bună, potrivit pentru un eveniment de exemplu',
        image: BackgroundImage,
        alt: 'alegria'
    },
    {
        name: 'Claudia Toma',
        testimonial: 'Am fost invitata la o nunta care a avut loc la aceasta locatie. Locul este foarte frumos, ca un mic colt de rai. A fost amenajat dragut, ospatarii au fost foarte prietenosi si s-au asigurat in permanenta ca totul este okay si ca nu lipseste nimic de la masa. Chiar mi-a placut!',
        image: BackgroundImage,
        alt: 'alegria'
    },
    {
        name: 'Razvan si Raluca',
        testimonial: 'Recomandam din toata inima. Multumim Restaurant Alegria pentru o nunta de vis! Mancarea delicioasa, servirea ireprosabila. Sunteti cei mai tari si ne bucuram ca am avut ocazia sa petrecem cel mai important eveniment din viata noastra alaturi de oameni ca voi.',
        image: BackgroundImage,
        alt: 'alegria'
    },
    {
        name: 'Madalina si George Ionescu',
        testimonial: 'A fost peste masura asteptarilor, de la modul cum localul a fost decorat, pana la servire. Totul a fost impecabil.',
        image: BackgroundImage,
        alt: 'alegria'
    }
];