import React from 'react';
import styled from 'styled-components/macro';
import ImageAntreuri from '../images/antreu.jpg';
import ImagePeste from '../images/fish.jpg';
import ImageSarmale from '../images/sarmalute.jpg';
import ImagePrincipal from '../images/meniu_principal.jpg';
// import ImageBauturi from '../images/bauturi_1.jpg';
import { Button } from './Button';
import { useHistory } from 'react-router-dom';

const MenuContainer = styled.div`
    position: relative;
    overflow: hidden;
    padding: 0 12px;
    max-width: 1170px;
    margin: 40px auto 0;
    display: flex;

    @media screen and (max-width: 768px) {
        width: 100%;
        flex-wrap: wrap;
    }
`;

const MenuType = styled.div`
    margin-bottom: 12px;
    width: 23%; 
    margin: 0 1%;
    background-color: #f5f2ed;  
    padding: 1.05rem 1.05rem 0.6rem;
    position: relative;
    text-align: center;

    @media screen and (max-width: 768px) {
        width: 48%;   
        margin-bottom: 2rem;     
    }

    @media screen and (max-width: 480px) {
        width: 100%;
        margin-bottom: 2rem;        
    }

    &:hover {
        filter: opacity(.8);
    }

    p {
        font-weight: 500;
        padding: 1em;
        color: #808369;
        font-size: 1.143rem;
    }
`;
const MenuImage = styled.img`
    width: 100%;
    height: 220px;
    cursor: pointer;
`;

const BtnWrap = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 6em;
`;

const Menu = () => {
    const menuType = [
        {
            id: 1,
            imgSrc: ImageAntreuri,
            description: 'Antreuri'
        },
        {
            id: 2,
            imgSrc: ImagePeste,
            description: 'Peşte'
        },
        {
            id: 3,
            imgSrc: ImageSarmale,
            description: 'Sarmale'
        },
        {
            id: 4,
            imgSrc: ImagePrincipal,
            description: 'Fel Principal'
        },
        // {
        //     id: 5,
        //     imgSrc: ImageBauturi,
        //     description: 'Băuturi'
        // },
    ];

    let history = useHistory();

    const redirect = () => {
        history.push('/meniuri')
      }

    return (
        <>
            <MenuContainer>
                {menuType.map((type, index) => {
                    return(
                        <MenuType key={index}>
                            <MenuImage src={type.imgSrc} onClick={redirect}/>
                            <p>{type.description}</p>
                        </MenuType>
                    )
                })}
            </MenuContainer>
            <BtnWrap>
                <Button primary="false" round="true" big="false" to="/meniuri">
                    Vezi Meniul Intreg
                </Button>
            </BtnWrap>
        </>
    )
}

export default Menu;
