import React from 'react';
import '../app.css';
import { useState } from 'react';

const MenuTabs = () => {
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
      setToggleState(index);
    };

    return (
        <div className="container">
            <div className="bloc-tabs">
                <button
                    className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(1)}
                    >Meniu Gold - <span className='menu-price'> 73 euro</span>
                    </button>
                    <button
                    className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(2)}
                    >Meniu Silver - <span className='menu-price'> 66 euro</span>
                    </button>
                    <button
                    className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(3)}
                    >Bauturi - <span className='menu-price'>incluse</span>
                </button>
            </div>
            <div className="content-tabs">
                <div className={toggleState === 1 ? "content  active-content" : "content"}>
                    <h2 className='categorie-meniu'>Aperitiv</h2>
                    <hr />
                    <p>Ruladă de somon fume</p>
                    <p>Ruladă de caşcaval cu şuncă praga şi struguri</p>
                    <p>Terină de pui în crustă de somon</p>
                    <p>Chips de cartof cu cremă de brânză crispy de bacon</p>
                    <p>Coşuleț cu salată de crudități şi babic</p>
                    <p>Avelină de icre</p>
                    <p>Bruschetă cu pastă de măsline uşor picantă</p>
                    <p>Trandafir de salam de Sibiu</p>
                    <p>Jambon de porc</p>
                    <p>Frigăruie cu Boccioni şi roşii cherry</p>
                    <p>Scalie de castravete</p>
                    <br />
                    <h2 className='menu-price'>Peste</h2>
                    <hr />
                    <p>Rulou din file de păstrăv curcubeu umplut cu mix de legume, orez basmati şi sos de portocale</p>
                    <br />
                    <h2 className='menu-price'>Sarmalute</h2>
                    <hr />
                    <p>Sarmale pe pat mămăligă cu bacon şi verdețuri</p>
                    <p>Smântână</p>
                    <p>Ardei iute</p>
                    <br />
                    <h2 className='menu-price'>Fel principal</h2>
                    <hr />
                    <p>Ceafă de porc marinată la grătar - 150g</p>
                    <p>Piept de pui marinată la grătar - 150g</p>
                    <p>Mix de salată de varză sau salată de murături</p>
                    <p>Cartof gratinat</p>
                </div>
                <div className={toggleState === 2 ? "content  active-content" : "content"}>
                <h2>Aperitiv</h2>
                    <hr />
                    <p>Ruladă de somon fume</p>
                    <p>Ruladă de caşcaval cu şuncă praga şi struguri</p>
                    <p>Terină de pui în crustă de somon</p>
                    <p>Chips de cartof cu cremă de brânză crispy de bacon</p>
                    <p>Coşuleț cu salată de crudități şi babic</p>
                    <p>Avelină de icre</p>
                    <p>Bruschetă cu pastă de măsline uşor picantă</p>
                    <p>Trandafir de salam de Sibiu</p>
                    <p>Jambon de porc</p>
                    <p>Frigăruie cu Boccioni şi roşii cherry</p>
                    <p>Scalie de castravete</p>
                    <br />
                    <h2>Sarmalute</h2>
                    <hr />
                    <p>Sarmale pe pat mămăligă cu bacon şi verdețuri</p>
                    <p>Smântână</p>
                    <p>Ardei iute</p>
                    <br />
                    <h2>Fel principal</h2>
                    <hr />
                    <p>Ceafă de porc marinată la grătar - 150g</p>
                    <p>Piept de pui marinată la grătar - 150g</p>
                    <p>Mix de salată de varză sau salată de murături</p>
                    <p>Cartof gratinat</p>
                </div>
                <div className={toggleState === 3 ? "content  active-content" : "content"}>
                    <h2>SAMPANIE</h2>
                    <hr />
                    <p>INCLUSA LA PRIMIRE</p>
                    <br />
                    <h2>WHISKY</h2>
                    <hr />
                    <p>J&B</p>
                    <p>JOHNNIE WALKER RED LABEL</p>
                    <br />
                    <h2>VODKA</h2>
                    <hr />
                    <p>SMIRNOFF</p>
                    <br />
                    <h2>VERMOUTH</h2>
                    <hr />
                    <p>GARRONE</p>
                    <br />
                    <h2>BITTER</h2>
                    <hr />
                    <p>CAMPARI</p>
                    <br />
                    <h2>GIN</h2>
                    <hr />
                    <p>GORDON’S</p>
                    <br />
                    <h2>LICHIOR</h2>
                    <hr />
                    <p>ANGELLI</p>
                    <br />
                    <h2>VIN</h2>
                    <hr />
                    <p>Crama Recaş - Castel Huniade 750 ml</p>
                    <p>Vin alb</p>
                    <p>Vin Roze</p>
                    <br />
                    <h2>SOFT BAR</h2>
                    <hr />
                    <p>Coca Cola, Fanta, Sprite (1 L)</p>
                    <p>Selecție de sucuri naturale</p>
                    <p>Apă minerală plată\carbogazoasă (0,75 l)</p>
                    <p>Cafea espresso Lavazza</p>
                    <p>Energizant</p>
                    <br />
                    <h2>COCKTAILS</h2>
                    <hr />
                    <span>La alegere pentru 4 euro în plus de persoană se poate opta pentru:</span>
                    <p>- Aperol Spritz</p>
                    <p>- Mojito</p>
                    <p>- Cuba Libre</p>
                </div>
            </div>
        </div>
    )
}

export default MenuTabs;
