import ImageOne from '../images/pool_1.jpg';
import ImageTwo from '../images/alegria-chairs.jpeg';

export const InfoData = {
    heading: "Descoperă	Povestea Noastra",
    paragraphOne: "Restaurantul Alegria este gazda perfectă pentru orice eveniment important din viața voastră.",
    paragraphTwo: "Servicii Profesionale într-o atmosferă plăcută alături de cei dragi doar la Restaurantul Alegria!",
    buttonLabel: 'Despre Noi',
    image: ImageOne,
    reverse: true,
    delay: 100
};

export const InfoDataTwo = {
    heading: "Organizăm Evenimente",
    paragraphOne: "Servicii Profesionale",
    paragraphTwo: "Nunți. Botezuri. Cununii in aer liber. Petreceri",
    buttonLabel: 'Descoperă',
    image: ImageTwo,
    reverse: false,
    delay: 300
};