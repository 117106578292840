import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
    width: 100%;
    height: 20%;
    padding: 0;
`;

const TitleWrap = styled.div`
    width: 300px;
    margin: 0 auto;
    position: relative;
    top: 180px;
    z-index: 3;

    h1 {
        font-size: clamp(1rem, 8vw, 2rem);
        font-weight: 400;
        text-transform: uppercase;
        text-align: center;
        z-index: 20;
        font-family: 'Yellowtail';
        
    }
`;

const TitleImage = styled.img`
    position: relative;
    top: -75px;
    left: 20px;
`;

const Title = ({ title, titleImage, alt }) => {
    return (
        <Section>
            <TitleWrap>
                <h1>{title}</h1>
                <TitleImage src={titleImage} alt={alt}/>
            </TitleWrap>
        </Section>
    )
}

export default Title;


