import React from 'react';
import styled from 'styled-components';
import { Button } from './Button';

const Section = styled.section`
    width: 100%;
    height: 740px;
    padding-top: 1.5rem;

    @media screen and (max-width: 768px) {
        height: auto;
        padding-top: 0rem;
    }
`;
const Container = styled.div`
    padding: 1.5rem calc((100vw - 1300px) / 2);
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    grid-remplate-rows: 800px;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;
const ColumnLeft = styled.div`
    line-height: 1.4,
    padding: 1rem 2rem;
    order: ${({reverse}) => (reverse ? '1' : '2')};
    position: relative;

    @media screen and (max-width: 768px) {
        order: ${({reverse}) => (reverse ? '1' : '2')};        
    }
`;

const InfoWrap = styled.div`
    padding: 0 2.5rem;

    h1 {
        margin-bottom: 1rem;
        font-size: clamp(1.5rem, 6vw, 2rem);

        @media screen and (max-width: 480px) {
            text-align: center;
        }
    }

    p {
        margin-bottom: 2rem;

        @media screen and (max-width: 480px) {
            text-align: center;
        }
    }
`;

const ColumnRight = styled.div`
    padding: 1rem 2rem;
    order: ${({reverse}) => (reverse ? '2' : '1')};
    position: relative;

    @media screen and (max-width: 768px) {
        order: ${({reverse}) => (reverse ? '1' : '2')};
        padding: 1.5rem 1.5rem 0rem !important;
    }
`;

const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;

    img {
        height: auto;
        width: 500px;    
        border: 15px solid #f5f2ed;
        border-radius: 10px;           

        @media screen and (max-width: 768px) {
            width: 100%
        }
    }
`;

const BtnWrapper = styled.div`
    a {
        @media screen and (max-width: 480px) {
            margin: 0 auto;
        }
    }
`;

const InfoSection = ({ heading, paragraphOne, paragraphTwo, buttonLabel, reverse, image }) => {
    return (
        <Section>
            <Container>
                <ColumnLeft reverse={reverse}>
                    <InfoWrap>
                        <h1>{heading}</h1>
                        <p>{paragraphOne}</p>
                        <p>{paragraphTwo}</p>
                        <BtnWrapper>
                            <Button to="/desprenoi" primary="true" >{buttonLabel}</Button>
                        </BtnWrapper>                        
                    </InfoWrap>
                </ColumnLeft>
                <ColumnRight reverse={reverse}>
                    <ImgWrap>
                        <img src={image} alt="alegria" />
                    </ImgWrap>                    
                </ColumnRight>
            </Container>
        </Section>
    )
}

export default InfoSection;
