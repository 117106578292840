import React from 'react';
import Hero from '../components/Hero';
import Testimonials from '../components/Testimonials';
import InfoSection from '../components/InfoSection';
import { InfoData, InfoDataTwo } from '../data/InfoData';
import { TitleData, MenuTitleData } from '../data/TitleData';
import { SliderData, TestimonialsData } from '../data/SliderData';
import Title from '../components/Title';
import TitluGalerie from '../components/TitluGalerie';
import Menu from '../components/Menu';

function Home() {
  return (
    <>
      <Hero slides={SliderData}/>
      <InfoSection {...InfoData}/>
      <InfoSection {...InfoDataTwo}/>   
      <TitluGalerie {...MenuTitleData} /> 
      <Menu />  
      <Title {...TitleData} />
      <Testimonials slides={TestimonialsData}/>      
    </>
  );
}

export default Home;
