import React from 'react';
import MenuTabs from '../components/MenuTabs';
import TitluGalerie from '../components/TitluGalerie';
import { MenuTitleData } from '../data/TitleData';
import FancyCake from '../components/FancyCake';

const Meniuri = () => {
    return (
        <>
            <TitluGalerie {...MenuTitleData} />
            <MenuTabs />
            <FancyCake />
        </>
    )
}

export default Meniuri;
