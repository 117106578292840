import React, { useState } from 'react';
import styled from 'styled-components/macro';
import '../app.css';
import CloseIcon from '@material-ui/icons/Close';
import ImageFour from '../images/galerie_1.jpg';
import ImageSeven from '../images/castanilor14.jpg';
import ImageNine from '../images/masa-noua.jpeg';
import ImageThree from '../images/galerie_4.jpg';
import ImageFive from '../images/balonase.jpeg';
import ImageSix from '../images/castanilor4.jpg';
import ImageOne from '../images/castanilor12.jpg';
import ImageEight from '../images/interior_1.jpg';
import ImageTwo from '../images/galerie_8.jpg';
import TitluGalerie from '../components/TitluGalerie';
import { GalleryTitleData } from '../data/TitleData';
import ReactPlayer from 'react-player/lazy';

const GallerySection = styled.div`
    position: relative;
    overflow: hidden;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 0 12px;

    @media screen and (max-width: 991px) {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }

    @media screen and (max-width: 480px) {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
    }

    h1 {
        text-align: center;
        margin: 0 auto;
    }
`;

const Gallery = styled.div`
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;    

    &:hover {
        filter: opacity(.8);
    }
`;

const GalleryImage = styled.img`
    width: 100%;
`;

const GalleryModalImage = styled.img`
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
`;

const Galerie = () => {
    const images = [
        {
            id: 1,
            imgSrc: ImageOne
        },
        {
            id: 2,
            imgSrc: ImageTwo
        },
        {
            id: 3,
            imgSrc: ImageThree
        },
        {
            id: 4,
            imgSrc: ImageFour
        },
        {
            id: 5,
            imgSrc: ImageFive
        },
        {
            id: 6,
            imgSrc: ImageSix
        },
        {
            id: 7,
            imgSrc: ImageSeven
        },
        {
            id: 8,
            imgSrc: ImageEight
        },
        {
            id: 9,
            imgSrc: ImageNine
        }
    ];

    const [modal, setModal] = useState(false);
    const [showTitle, setShowTitle] = useState(true);
    const [tempImgSrc, setTempImgSrc] = useState('');
    const getImg = (imgSrc) => {
        setTempImgSrc(imgSrc);
        setModal(true);
        setShowTitle(false);
    };

    return (
        <>  
            <div className={modal ? "modal open" : "modal"}>
                <GalleryModalImage src={tempImgSrc}/>
                <CloseIcon onClick={() => {setModal(false); setShowTitle(true)}} />
            </div>
            <div className={showTitle ? "show" : "hide"}>
                <TitluGalerie {...GalleryTitleData} /> 
            </div>    
            <GallerySection>                    
                {images.map((image, index) => {
                    return(
                        <Gallery key={index}>
                            <GalleryImage src={image.imgSrc} onClick={() => getImg(image.imgSrc)}/>
                        </Gallery>
                    )
                })}
            </GallerySection>
            <GallerySection>
                <ReactPlayer className='video-clip' url='https://youtube.com/shorts/Sh2XPMUmJm8' muted/>
                <ReactPlayer className='video-clip' url='https://www.youtube.com/watch?v=mPa_Vaye_pI' muted/>
                <ReactPlayer className='video-clip' url='https://youtube.com/shorts/QRm5GxG5jOA' muted/>
            </GallerySection> 
            <GallerySection>
                <ReactPlayer className='video-clip' url='https://www.youtube.com/shorts/hS_FkIHL_v4' muted/>
                <ReactPlayer className='video-clip' url='https://www.youtube.com/shorts/atd_GPuVo0E' muted/>
            </GallerySection> 
        </>
    )
}

export default Galerie;
