import React from 'react';
import Harta from '../components/Harta';
import Contact from '../components/Contact';
import {  ContactTitleData } from '../data/TitleData';
import TitluGalerie from '../components/TitluGalerie';

const ContactUs = () => {
    return (
        <>
            <TitluGalerie {...ContactTitleData} />
            <Contact />
            <Harta />
        </>
    )
}

export default ContactUs;
