import TitleImage from '../images/title_floral.png';

export const TitleData = {
    title: 'Testimoniale',
    titleImage: TitleImage,
    alt: 'alegria'
};

export const GalleryTitleData = {
    title: 'Galerie Foto',
    titleImage: TitleImage,
    alt: 'alegria'
};

export const ContactTitleData = {
    title: 'Rezerva Acum',
    titleImage: TitleImage,
    alt: 'alegria'
};

export const AboutTitleData = {
    title: 'Despre Noi',
    titleImage: TitleImage,
    alt: 'alegria'
};

export const EventsTitleData = {
    title: 'Evenimente',
    titleImage: TitleImage,
    alt: 'alegria'
};

export const MenuTitleData = {
    title: 'Meniuri',
    titleImage: TitleImage,
    alt: 'alegria'
};